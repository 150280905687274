import MDinput from '@/components/MDinput';
import { validatorRequire } from '@/utils/validators';
import MultiLanguageInput from '@/components/DSE/MultiLanguageInput';
import PriceInput from '../components/PriceInput';
import ImageInput from '@/components/DSE/ImageInput';
import RestaurantSelector from '@/pages/masterdata/components/RestaurantSelector';
import Switch from '@/components/DSE/Switch';
import TimePicker from '@/components/DSE/TimePicker';
import Select from '@/components/DSE/Select';
import NumericInput from '@/components/DSE/NumericInput';
import { VATRateOption } from '../../../utils/constants';
import { validatorRequireNumber } from '../../../utils/validators';
import { getMenuBlockTypeOptions } from '@/utils/constants';

export function getFormDefinition(vue) {
  return {
    type: 'tab',
    saveButtons: [true, false, false, false, false], // Page, tab 1, tab 2, ...
    showFormLabels: [true, true, true, false], // Tab 1, tab 2, ....
    labels: ['common.tabGeneral', 'common.translations', 'product.tabNutritionalValues', 'product.tabRestaurants'],
    headerTemplate: (str, form) => `
    <h3>
    ${form.name || ''}
    </h3>
    `,
    groups: [
      {
        // Tab 1
        name: {
          type: MDinput,
          defaultValue: '',
          props: {
            maxlength: 64,
            required: true
          },
          rules: [{ validator: validatorRequire }]
        },
        menuCategoryID: {
          type: Select,
          defaultValue: null,
          props: {
            caption: 'common.menuCategory',
            valueField: 'id',
            labelField: 'name',
            loading: vue.$store.getters['menuCategory/isLoading'],
            options: vue.$store.getters['menuCategory/loadedItems']
          }
        },
        productCategoryID: {
          type: Select,
          defaultValue: null,
          props: {
            caption: 'common.productCategory',
            valueField: 'id',
            labelField: 'name',
            loading: vue.$store.getters['productCategory/isLoading'],
            options: vue.$store.getters['productCategory/loadedItems']
          }
        },
        vatRateOption: {
          type: Select,
          defaultValue: VATRateOption.Reduced,
          props: {
            valueField: 'code',
            labelField: 'description',
            loading: vue.$store.getters['option/isLoading'],
            options: vue.$store.getters['option/loadedOptions']('vatRate'),
            required: true
          },
          rules: [{ validator: validatorRequireNumber }]
        },
        prices: {
          type: PriceInput,
          props: {
            caption: 'product.prices'
          }
        },
        deposit: {
          type: MultiLanguageInput,
          props: {
            min: 0,
            max: 999,
            precision: 0,
            step: 1,
            caption: 'product.deposit',
            type: 'number',
            isNumeric: true
          }
        },
        takeawayID: {
          type: MDinput,
          defaultValue: null,
          props: {
            type: 'number',
            precision: 0,
            showThousandSeparator: false
          }
        },
        loyaltyPoints: {
          type: MDinput,
          defaultValue: 0,
          props: {
            type: 'number',
            precision: 0,
            showThousandSeparator: false
          }
        },
        vipOnlyYN: {
          type: Switch,
          defaultValue: false,
          props: {
            caption: 'product.vipOnlyYN'
          }
        },
        ean: {
          type: MDinput,
          defaultValue: null,
          props: {
            maxlength: 13
          }
        },
        closingTime: {
          type: TimePicker,
          defaultValue: null,
          props: {
            maxlength: 5,
            caption: 'product.closingTime'
          }
        },
        menuBlockType: {
          type: Select,
          defaultValue: null,
          props: {
            caption: 'product.menuBlockType',
            valueField: 'code',
            labelField: 'description',
            options: getMenuBlockTypeOptions(vue)
          }
        },
        toGoYN: {
          type: Switch,
          defaultValue: false,
          props: {
            caption: 'common.toGo'
          }
        },
        showInNewBlockInCashDeskYN: {
          type: Switch,
          defaultValue: false,
          props: {
            caption: 'product.showInNewBlockInCashDeskYN'
          }
        },
        image: {
          type: ImageInput,
          props: {
            height: 75,
            caption: 'product.image'
          }
        },
        smallImage: {
          type: ImageInput,
          props: {
            height: 75,
            caption: 'product.smallImage'
          }
        },
        largeImage: {
          type: ImageInput,
          props: {
            height: 75,
            caption: 'product.largeImage'
          }
        },
        position: {
          type: NumericInput,
          defaultValue: 0,
          props: {
            type: 'number',
            precision: 0,
            caption: 'common.position'
          }
        },
        supHeffingYN: {
          type: Switch,
          defaultValue: false,
          props: {
            caption: 'product.supChargeYN'
          }
        },
        onlineYN: {
          type: Switch,
          defaultValue: false,
          props: {
            activeText: 'common.yes',
            inactiveText: 'common.no'
          }
        }
      },
      {
        // Tab 2
        displayName: {
          type: MultiLanguageInput,
          props: {
            maxlength: 64,
            caption: 'common.displayName'
          }
        },
        nameOnTicket: {
          type: MultiLanguageInput,
          props: {
            maxlength: 32,
            caption: 'product.nameOnTicket'
          }
        },
        description: {
          type: MultiLanguageInput,
          props: {
            maxlength: 512,
            caption: 'product.description',
            type: 'textarea'
          }
        },
        subtitle: {
          type: MultiLanguageInput,
          props: {
            maxlength: 255,
            caption: 'product.subtitle'
          }
        }
      },
      {
        // Tab 3
        nutritionValuesUnit: {
          type: MDinput,
          defaultValue: '',
          props: {
            maxlength: 8,
            caption: 'product.nutritionValuesUnit'
          }
        },
        kiloJoule: {
          type: MDinput,
          defaultValue: 0,
          props: {
            type: 'number',
            precision: 0,
            showThousandSeparator: false,
            caption: 'product.kiloJoule'
          }
        },
        kiloCalorie: {
          type: MDinput,
          defaultValue: 0,
          props: {
            type: 'number',
            precision: 0,
            showThousandSeparator: false,
            caption: 'product.kiloCalorie'
          }
        },
        fat: {
          type: MDinput,
          defaultValue: 0,
          props: {
            type: 'number',
            precision: 0,
            showThousandSeparator: false,
            caption: 'product.fat'
          }
        },
        digestibleCarbohydrates: {
          type: MDinput,
          defaultValue: 0,
          props: {
            type: 'number',
            precision: 0,
            showThousandSeparator: false,
            caption: 'product.digestibleCarbohydrates'
          }
        },
        glutenYN: {
          type: Switch,
          defaultValue: true,
          props: {
            default: true,
            caption: 'product.glutenYN'
          }
        },
        cowsMilkProteinYN: {
          type: Switch,
          defaultValue: true,
          props: {
            default: true,
            caption: 'product.cowsMilkProteinYN'
          }
        }
      },
      {
        // Tab 4
        restaurants: {
          type: RestaurantSelector,
          props: {
            caption: 'product.restaurants'
          }
        }
      }
    ]
  };
}

export default getFormDefinition;

export function createItemFromFormData(vue, form) {
  return {
    ...form
  };
}

export function createFormDataFromItem(vue, item) {
  return {
    ...item
  };
}
